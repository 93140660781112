<template>
  <available-components-for-theme7 :specific-components="components" />
</template>
<script lang="ts">
import AvailableComponentsForTheme7 from '~/components/theme7/AvailableComponentsForTheme7.vue';
import { ViewsMixin } from '~/mixins/views.mixin';

export default defineNuxtComponent({
  name: 'Theme7View',

  mixins: [ViewsMixin],

  components: {
    AvailableComponentsForTheme7,
  },

  description:
    'Components that can be used through this theme should be imported here in order to use them dynamically',
});
</script>
